<template>
  <div class="pa-3">
    <div class="px-4 pt-6">
      <h3 class="page-title mb-7 font-weight-bold">All Services</h3>
      <v-row class="no-gutters" align="center">
        <v-col cols="4">
          <v-text-field
            placeholder="キーワード検索"
            v-model="search"
            @input="getDataFromApi"
            dense
            outlined
          >
            <v-btn
              slot="append"
              color="blue"
              small
              @click="getDataFromApi"
              class="white--text"
              elevation="0"
              height="23"
            >
              検索
            </v-btn>
          </v-text-field>
        </v-col>
        <v-col class="text-right">
          <v-btn
            height="30"
            class="btn-new mb-7"
            color="#CFD3FE"
            depressed
            min-width="100"
            to="/management/car/register"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            車両情報登録
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <Table
      :headers="headers"
      :items="services"
      search="test"
      :total-records="servicePagination.records_total"
      :number-of-pages="servicePagination.total_pages"
      :loading="loading"
    >
      <template v-slot:item.id="{ item }">
        <span>{{ item.id }}</span>
      </template>
      <template v-slot:item.actions class="mt-2">
        <v-btn color="primary mr-2" fab x-small>
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <!--        <v-btn color="error" fab x-small>-->
        <!--          <v-icon small>mdi-delete</v-icon>-->
        <!--        </v-btn>-->
      </template>
    </Table>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
export default {
  name: 'List',
  components: { Table },
  data() {
    return {
      headers: [
        { text: 'id', align: 'center', value: 'id' },
        { text: 'name', align: 'center', value: 'name', sortable: false },
        {
          text: 'actions',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      loading: false
    }
  },
  computed: {
    services() {
      return this.$store.getters.allServices
    },
    servicePagination() {
      return this.$store.getters.servicePagination
    }
  },

  created() {
    this.getDataFromApi()
  },
  methods: {
    async getDataFromApi() {
      this.loading = true
      let params = {}
      params.paginate = 10
      await this.$store.dispatch('SERVICE_GET_ALL', params)
      this.loading = false
    }
  }
}
</script>

<style src="./List.scss" lang="scss" scoped></style>
