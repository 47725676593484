var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-3"
  }, [_c('div', {
    staticClass: "px-4 pt-6"
  }, [_c('h3', {
    staticClass: "page-title mb-7 font-weight-bold"
  }, [_vm._v("All Services")]), _c('v-row', {
    staticClass: "no-gutters",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "キーワード検索",
      "dense": "",
      "outlined": ""
    },
    on: {
      "input": _vm.getDataFromApi
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "slot": "append",
      "color": "blue",
      "small": "",
      "elevation": "0",
      "height": "23"
    },
    on: {
      "click": _vm.getDataFromApi
    },
    slot: "append"
  }, [_vm._v(" 検索 ")])], 1)], 1), _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    staticClass: "btn-new mb-7",
    attrs: {
      "height": "30",
      "color": "#CFD3FE",
      "depressed": "",
      "min-width": "100",
      "to": "/management/car/register"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" 車両情報登録 ")], 1)], 1)], 1)], 1), _c('Table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.services,
      "search": "test",
      "total-records": _vm.servicePagination.records_total,
      "number-of-pages": _vm.servicePagination.total_pages,
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(item.id))])];
      }
    }, {
      key: "item.actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "primary mr-2",
            "fab": "",
            "x-small": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }